<template>
  <div class="md-layout md-gutter">
    <div 
      class="md-layout-item md-xlarge-size-33 md-large-size-33 md-medium-size-33 md-small-size-50 md-xsmall-size-100"
      v-for="item in items" :key="item.id"
    >
      <div class="mb-6">
        <BoxNavigationCard :item="item" />
      </div>
    </div> 
  </div>
</template>

<script>
import data from '@/data/material/data'
import BoxNavigationCard from "@/components/molecule/BoxNavigationCard";
export default {
  data() {
    return {
      items: data.mainNavigation
    }
  },
  components: {
    BoxNavigationCard,
  },
};
</script>

<style></style>
